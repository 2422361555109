<template>
  <div>
    <div class="flex justify-between items-center mt-3 pl-2">
      <h3 class="font-semibold text-lg
      border-b-4 border-dashblack">
        All Customers
      </h3>
      <div class="flex items-center">
        <div class="flex items-center mx-4">
          <p class="text-card text-md font-semibold pr-2">Filter by</p>
        </div>
        <div class="mr-4">
          <el-input placeholder="Search" v-model="searchQuery" @input="search"></el-input>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <el-table style="width: 100%" :data="loans.customers" v-loading="loans.loader">
        <el-table-column type="index" label="S/N" width="50">
        </el-table-column>
        <el-table-column label="Full Name">
          <template slot-scope="scope">
            <span>{{ scope.row.first_name + " " + scope.row.last_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Email">
        </el-table-column>
        <el-table-column prop="phone" label="Phone Number"></el-table-column>
        <el-table-column prop="loan_level" label="Loan Level"></el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <div class="flex items-center">
              <span class="mx-3 cursor-pointer" @click="viewUser(scope.row.id)">
                <img src="@/assets/img/eye.svg" alt="">
              </span>
              <span class="mx-3 cursor-pointer" @click="editUser(scope.row.id)">
                <img src="@/assets/img/edit.svg" alt="">
              </span>
              <span v-if="scope.row.is_verified === 0"
                class="text-dashgreen border border-dashgreen rounded px-4 py-1 cursor-pointer"
                @click="verifyUser(scope.row.id)">
                Verify User
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-10" v-if="loans.customers.length > 0">
        <el-pagination background @size-change="customersHandleSizeChange" @current-change="customersHandleCurrentChange"
          style="float: right;" :current-page.sync="customerCurrentPage" :page-sizes="pageSizes"
          :page-size="loans.perPage" layout="total, sizes, prev, pager, next, jumper" :total="loans.customersTotal">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="editUserModal" width="40%" @close="handleClose">
      <div class="p-6">
        <h3 class="text-dashblack font-bold text-2xl pb-8">Edit Customer</h3>
        <div>
          <el-form ref="oneUser" :model="oneUser" :rules="rules">
            <el-form-item prop="date_of_birth">
              <div class="flex flex-col">
                <label class="font-semibold">Date of Birth</label>
                <el-date-picker v-model="oneUser.date_of_birth" class="w-input" @change="selectDate"
                  placeholder="Enter Date of Birth" style="width: 100%;"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item prop="gender">
              <div class="flex flex-col">
                <label class="font-semibold">Gender</label>
                <el-input v-model="oneUser.gender" class="w-input" placeholder="Enter Gender"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="home_address">
              <div class="flex flex-col">
                <label class="font-semibold">Home Address</label>
                <el-input v-model="oneUser.home_address" class="w-input" placeholder="Enter Home Address"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="work_address">
              <div class="flex flex-col">
                <label class="font-semibold">Work Address</label>
                <el-input v-model="oneUser.work_address" class="w-input" placeholder="Enter Work Address"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="occupation">
              <div class="flex flex-col">
                <label class="font-semibold">Occupation</label>
                <el-input v-model="oneUser.occupation" class="w-input" placeholder="Enter Occupation"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="loan_level_id">
              <div class="flex flex-col">
                <label class="font-semibold">Loan Level</label>
                <el-select v-model="oneUser.loan_level_id" class="w-input" placeholder="Enter Loan Level"
                  @change="pickLoanId">
                  <el-option v-for="(item, index) in loans.allLoanLevels" :key="index" :label="item.name"
                    :value="item.level">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <div>
              <button @click.prevent="handleEdit"
                class="rounded text-white font-semibold bg-dashblack px-6 py-3 mt-4 w-32 focus:outline-none">
                Submit
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  data() {
    return {
      pageSizes: this.$store.state.pageSizes,
      value: '',
      editUserModal: false,
      oneUser: {},
      input: '',
      options: [
        {
          value: 'enabled',
          label: 'Enabled'
        },
        {
          value: 'disabled',
          label: 'Disabled'
        }
      ],
      rules: {
        date_of_birth: [
          {
            required: true,
            message: 'Please enter date of birth'
          }
        ],
        gender: [
          {
            required: true,
            message: 'Please enter gender'
          }
        ],
        home_address: [
          {
            required: true,
            message: 'Please enter home address'
          }
        ],
        occupation: [
          {
            required: true,
            message: 'Please enter occupation'
          }
        ],
        work_address: [
          {
            required: true,
            message: 'Please enter work address'
          }
        ],
      }
    }
  },
  mounted() {
    this.getAllCustomers()
  },
  computed: {
    ...mapState(['loans']),
    customerCurrentPage: {
      get() {
        return this.loans.customersCurrentPage
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'customersCurrentPage',
          with: value
        })
      }
    },
    searchQuery: {
      get() {
        return this.loans.searchQuery
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'searchQuery',
          with: value
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllCustomers',
      'viewOneUser',
      'updateCustomer',
      'getAllLoansLevels',
      'customersHandleSizeChange',
      'customersHandleCurrentChange',
      'handleVerifyUser'
    ]),
    currencyFormat(number) {
      return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
    },
    viewUser(id) {
      this.$router.push({ name: 'view-user', params: { id: id } });
    },
    search: debounce(function () {
      this.getAllCustomers()
    }, 500),
    handleClose() {
      this.oneUser = {}
    },
    pickLoanId(val) {
      this.oneUser.loan_level_id = val
    },
    verifyUser(id) {
      this.handleVerifyUser(id)
        .then((res) => {
          if (res.status === 200) {
            this.$toastr.success(res.data.message)
            this.getAllCustomers()
          }
        })
    },
    editUser(id) {
      this.viewOneUser(id)
        .then((res) => {
          this.oneUser = res.data.data
          this.editUserModal = true
          this.getAllLoansLevels()
        })
    },
    selectDate(val) {
      this.oneUser.date_of_birth = val
    },
    handleEdit() {
      this.$refs['oneUser'].validate((valid) => {
        if (valid) {
          const payload = {
            id: this.oneUser.id,
            date_of_birth: this.oneUser.date_of_birth !== null ? this.oneUser.date_of_birth : this.oneUser.date_of_birth.toISOString(),
            gender: this.oneUser.gender,
            home_address: this.oneUser.home_address,
            work_address: this.oneUser.work_address,
            occupation: this.oneUser.occupation,
            loan_level_id: this.oneUser.loan_level_id
          }
          this.updateCustomer(payload)
            .then((res) => {
              if (res.status === 200) {
                this.$toastr.success(res.data.message)
                this.editUserModal = false
                this.getAllCustomers()
              }
            })
            .catch((err) => {
              this.$toastr.error(err.response.data.message)
            })
        }
      })
    },
  }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #11141A;
  color: #FFF;
}

.el-table th>.cell {
  color: #11141A;
}
</style>